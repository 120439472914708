import { gql, useMutation } from '@apollo/client'
import { LOCATION_DATA } from './locations'

const INSTRUMENT_DATA =  gql`
        fragment InstrumentData on InstrumentGenericType {
            id
            panId
            active
            pgn
            notes
            hardwarestatus {
              id
              time
              data
              summary
              user {
                username
              }
            }
            networkOperator {
              username
            }
            comments {
              id
              user {
                username
              }
              text
              time
            }
            calibrations {
              id
              opened
              closed
              comment
              user {
                username
              }
              data
            }
            deployment {
              id
              time
              description
              location {
                id
                name
              }
              user {
                username
              }
            }
            stati {
              id
              time
              description
              status {
                id
                name
              }
              user {
                username
              }
            }
            issues {
              id
              opened
              closed
              issue {
                id
                description
                category {
                  id
                  name
                }
              }
              user {
                username
              }
              actions {
                id
                time
                description
                user {
                  username
                }
              }
            }
        }
`;

export const INSTRUMENT_DETAIL = gql `
query InstrumentDetail($input: ID!) {
  instrument(id: $input){
    ...InstrumentData
  }
}
${INSTRUMENT_DATA}
`;

export const CREATE_COMMENT = gql `
mutation CreateComment($input: CommentCreateGenericType!) {
  createComment(newComment: $input) {
    ok
    comment {
      id
      user {
        username
      }
      text
      time
    }
  }
}
`

export const UPDATE_COMMENT = gql `
mutation UpdateComment($input: CommentUpdateGenericType!) {
  updateComment(newComment: $input) {
    ok
    comment {
      id
      user {
        username
      }
      text
      time
    }
  }
}
`

export const DELETE_COMMENT = gql `
mutation DeleteComment($input: ID!) {
  deleteComment(id: $input) {
    ok
    comment {
      id
      user {
        username
      }
      text
      time
    }
  }
}
`


/*export const GET_INSTRUMENTS = gql`
query {
    allInstruments {
        results {
            ...InstrumentData
        }
        totalCount
    }
}
${INSTRUMENT_DATA}
`;*/

export const GET_INSTRUMENTS = gql`
query {
    instruments {
        ...InstrumentData
    }
}
${INSTRUMENT_DATA}
`;


export const GET_INSTRUMENTS_AND_HEADERS = gql`
    query GetInstrumentsAndHeaders($start: CustomDateTime, $end: CustomDateTime) {
        instruments {
            ...InstrumentData
            partialHeaders(time_Gte: $start, time_Lte: $end) {
                time
                data 
            }
        }
    }
    ${INSTRUMENT_DATA}
`;


export const LIST_INSTRUMENTS = gql`
query {
  instruments {
    id
  }
}
`

export const UPDATE_INSTRUMENT_MUTATION = gql`
  mutation UpdateInstrumentMutation($input: UpdateInstrumentMutationInput!) {
    updateInstrumentMutation(input: $input) {
      ok
      instrument {
        ...InstrumentData
      }
    }
  }
  ${INSTRUMENT_DATA}
`;



